import React from 'react';
import { Layout, Seo } from 'components';

// import page style
import './about.scss';

const AboutPage = () => {
  return (
    <Layout indication="patient" className="patient-about">
      <Seo pageTitle="Patient About Page" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actuall content goes here */}
          <h1>Patient About Page</h1>

          <div style={{ maxWidth: 600, margin: 'auto', marginTop: 20 }}>
            <h2
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: 'green',
                paddingBottom: 25,
                textAlign: 'center',
              }}
            >
              test
            </h2>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
